
var global = this;

(function () {
  'use strict'

  var Logger = (function () {
    var logPushWorkerUrl = ''
    var browserLogging = ''

    var Logger = {}
    Logger.initialize = function (options) {
      // 1. if this is called again (should not happen), servars.logging is undefiend
      // 2. we only enable this module in production
      // 3. make sure we have the url
      if (Logger.initialized) return

      Logger.initialized = true
      /**
       * This is were we'll save the log lines to batch send them to logdna
       * @type {Array}
       */
      this._logLines = []
      this._logTimeout = null

      /**
       * How many logs we should collect
       * before sending them to logdna
       * @type {Number}
       */
      this.batchSize = 100

      /**
       * How many ms we should wait for the queue to fill up
       * If it doesn't just send the logs that we have until then
       * @type {Number}
       */
      this.queueTimeout = 10000

      /**
       * The default app name in logdna for these logs
       * @type {String}
       */
      this.defaultApp = 'frontend'
      this.defaultLogLevel = 'debug'

      /**
       * The logs from debug go to a different app
       * @type {String}
       */
      this.debugAppName = 'debug-frontend'

      /**
       * Just a flag to keep track if we should buffer debug or send them to logdna
       * @type {Boolean}
       */
      this._enabled = false

      /**
       * If we've received an additional metadata object, use it
       * These will be added to every log
       * @type {Object}
       */
      this.metaData = options.metaData || {}

      // save the url internally
      logPushWorkerUrl = options.logPushWorkerUrl
      browserLogging = options.browserLogging
      // if we want to only send console log, or everything
      if (['console', 'all'].indexOf(options.browserLogging) !== -1) {
        // initiliaze the listeners to console.log, etc
        this.initLogListeners()
      }

      // if we want to only send debug calls, or everything
      if (['debug', 'all'].indexOf(options.browserLogging) !== -1) {
        // replace the global debug function with our own
        this.replaceDebug()
      }

      this.addOnCloseFlushCallback(() => this.flushLogsOnClose())
      this.initOnErrorListener()
    }

    Logger.initOnErrorListener = function () {
      var context = this.metaData.context || 'window'
      global.addEventListener('error', (event) => {
        console.error(`Unhandled error from ${context}:`, event.error || event.message)
      })
      global.addEventListener('messageerror', ({ origin, data, source, ports, lastEventId }) => {
        console.error(`Message error from ${context}:`, { origin, source: source.toString(), data: data.toString(), ports, lastEventId })
      })
      global.addEventListener('unhandledrejection', async ({ reason }) => {
        console.error(`Unhandled rejection from ${context}:`, reason)
        if (reason instanceof Response) console.error(await reason.text())
      })
    }

    const originalConsoles = {
      'verbose': console.debug.bind(console.debug),
      'debug': console.debug.bind(console.debug),
      'log': console.log.bind(console.log),
      'info': console.log.bind(console.log),
      'warn': console.warn.bind(console.warn),
      'error': console.error.bind(console.error),
      'err': console.error.bind(console.error)
    }

    function wrappedLogFunction (level) {
      if (!Object.keys(originalConsoles).includes(level)) {
        const message = 'An invalid log level was used. (' + level + ') must be one of ' + Object.keys(originalConsoles).join(', ')
        // make it _super_ obvious
        alert(message)
        console.trace(message)
      }
      const targetConsoleFunction = originalConsoles[level]
      return function () {
        /*
          We need to check to see if we have a JSON log, or a regular log.
          A JSON log will have a single object as the first argument
         */
        let message = arguments[0]
        if (typeof message === 'object' && message && message.isJson) {
          // JSON log
          targetConsoleFunction.apply(targetConsoleFunction, message)
          Logger._logLines.push(message)
          Logger.sendLogToGrafana()
        } else {
          // Not a JSON log
          try {
            Logger.saveLog({
              logLine: Logger.createLogLine(arguments),
              level: level
            })
          } finally {
            targetConsoleFunction.apply(targetConsoleFunction, arguments)
          }
        }
      }
    }

    /**
     * Used to wrap the main console methods and collect logs
     * @return {[type]} [description]
     */
    Logger.initLogListeners = function () {
      // wrap native methods
      console.debug = wrappedLogFunction('debug')
      console.log = wrappedLogFunction('log')
      console.warn = wrappedLogFunction('warn')
      console.error = wrappedLogFunction('err')
    }

    Logger.addOnCloseFlushCallback = function (cb) {
      if (!global.window) return

      // https://github.com/GoogleChromeLabs/page-lifecycle/blob/master/src/Lifecycle.mjs
      // Safari does not reliably fire the `pagehide` or `visibilitychange`
      const isSafari = typeof safari === 'object' && safari.pushNotification
      const isPageHideSupported = 'onpageshow' in self

      // IE9-10 do not support the pagehide event, so we fall back to unload
      // pagehide event is more reliable but less broad than unload event for mobile and modern browsers
      const pageCloseEvent = isPageHideSupported && !isSafari ? 'pagehide' : 'unload'

      global.addEventListener(pageCloseEvent, cb)
    },

    Logger.flushLogsOnClose = function () {
      console.log('Page is being closed, flushing logs from ' + (this.metaData.context || 'window'))
      this.sendLogToGrafana(true)
    }

    /**
     * Used to get a log level depending on the namespace
     * @param  {String} namespace The debug namespace
     * @return {String}           The log level: info, debug (default), error
     */
    Logger.getLogLevel = function (namespace, logLine) {
      // if we have the string Error: in the line, it's most likely a js error
      // the log level should be error in this case
      if (logLine && logLine.indexOf('Error: ') > -1) {
        return 'error'
      }

      return ['zc:recorder', 'zc:project'].includes(namespace) ? 'info' : 'debug'
    }

    /**
     * Used to replace the global debug function
     */
    Logger.replaceDebug = function () {
      var self = this

      // get the native debug to use it later
      var nativeDebug = global.debug && global.debug.bind(global.debug)
      // keep track of debug namespaces
      var debugNamespaces = {}

      global.debug = function (namespace) {
        // get the native debug method, namespaced
        debugNamespaces[namespace] = nativeDebug && nativeDebug.call(nativeDebug, namespace)

        // just return a function that will save all the args
        // and keeping track of the namespace
        return function () {
          try {
            var logLine = namespace + ' ' + self.createLogLine(arguments)
            self.saveLog({
              // also add the namespace to the line to make it more visible
              logLine: logLine,
              level: self.getLogLevel(namespace, logLine),
              // the name of the app in logdna
              app: self.debugAppName,
              metaData: {
                namespace: namespace
              }
            })
          } finally {
            // call the native debug
            debugNamespaces[namespace] && debugNamespaces[namespace].apply(debugNamespaces[namespace], arguments)
          }
        }
      }
    }

    /**
     * Enable the sending the debugs to logdna
     * this is called only on the recording page
     */
    Logger.enableDebugModule = function () {
      this._enabled = true
    }

    Logger.createLogLine = function (args) {
      // make it an array
      args = Array.prototype.slice.call(args)

      try {
        return args.map(function (arg) {
          if (typeof arg === 'object') {
            // if it's type ErrorEvent it will have a message attribute
            if (arg.stack || arg.message) {
              return arg.stack || arg.message
              // if it's a blob
            } else if (arg instanceof Response) {
              return JSON.stringify({ status: arg.status, url: arg.url, headers: [...arg.headers.entries()].map(h => `${h[0]}: ${h[1]}`).join('\n') })
            } else if (arg instanceof Blob) {
              return 'Blob, size ' + arg.size
            } else if (ArrayBuffer.isView(arg)) {
              return arg.constructor.name + ' ' + arg.length
            } else {
              // else, just stringify it
              return JSON.stringify(arg)
            }
          } else {
            // if string, array, etc
            return arg.toString()
          }
        }).join(' ')
      } catch (e) {
        // in case we have any problem parsing the args
        // just glue them together and return
        return Array.prototype.join.call(args, ' ')
      }
    }

    Logger.createMetadataObject = function (object) {
      var app = global.app
      var metaData = Object.assign({}, object)

      if (app && app.user) {
        metaData['userId'] = app.user.id
        metaData['userName'] = app.user.get('displayName')
      }

      if (app && app.project) {
        metaData['projetName'] = app.project.get('name')
        metaData['projectId'] = app.project.id

        if (app.project.recorder && app.project.recorder.recording) {
          metaData['recordingId'] = app.project.recorder.recording.id
        }
      }

      if (global.servars && global.servars.pipelineVersion) {
        metaData['pipelineVersion'] = global.servars.pipelineVersion
      }

      if (!metaData.context && global.window) {
        metaData['context'] = 'window'
        metaData['url'] = global.window.location.href
      }

      return metaData
    }

    /**
     * Used to add metadata to a log line and add to queue
     * @param  {Object} options
     */
    Logger.saveLog = function (options) {
      var logLine = options.logLine
      var level = options.level || this.defaultLogLevel

      if (!logLine || !level) return

      /**
       * Metadata for this log line
       * add any metadata that might come from the caller
       * Object.assign works even if options.metaData is undefined
       * @type {Object}
       */
      var metaData = Object.assign(this.metaData, options.metaData, logLine.metadata)
      metaData = this.createMetadataObject(metaData)

      // Don't send verbose logs to grafana
      if (this.isVerboseLog(logLine)) return

      this._logLines.push({
        // TODO: this does not take in account if the user's clock is off
        'timestamp': new Date().getTime(),
        'line': logLine,
        'level': level,
        'meta': metaData
      })

      this.sendLogToGrafana()
    }

    /**
     * Determines if a log was made at `verbose` level
     * @param {string | Object} log The log line to check
     * @returns {boolean} True if the log is a verbose log, false otherwise
     */
    Logger.isVerboseLog = function (log) {
      return typeof log === 'string' ? log.toLowerCase().includes('verbose') : log.level === 'verbose'
    }

    /**
     * Batch calls to logDNA
     * @param  {Bool} force If we want to force the send
     */
    Logger.sendLogToGrafana = function (force) {
      // proceed only if we have enabled sending the logs
      // this should only be true on the recording page
      if (!this._enabled || !this._logLines.length) return
      if (browserLogging === 'disabled' || !logPushWorkerUrl) return

      var self = this
      global.clearTimeout(this._logTimeout)

      // batch calls to logdna
      if (this._logLines.length < this.batchSize && force !== true) {
        this._logTimeout = setTimeout(function () {
          self.sendLogToGrafana(true)
        }, this.queueTimeout)
        return
      }

      /**
       * load the logs in a temporary array
       * @type {Array}
       */
      var buffer = this._logLines.slice(0)
      // clear this one in order to be used as the request gets made
      // if it fails the buffer lines will be added at the beginning to be sent again
      this._logLines = []

      /**
       * The object that will be sent to logdna
       * @type {Object}
       */
      var opts = {
        lines: buffer
      }

      global.fetch(logPushWorkerUrl, {
        method: 'post',
        body: JSON.stringify(opts),
        keepalive: true,
        headers: { 'content-type': 'application/json' }
      })
      .then(function (response) {
        if (response.ok) {
          // reset the array
          buffer = []
        } else {
          // something went wrong

          // add the lines at the beggining to be sent again
          self._logLines = buffer.concat(self._logLines)
          buffer = []

          self.surfaceError(data)
        }
      })
      .catch(function (data) {
        self.surfaceError(data)
      })
    }

    /**
     * Used to surface an error to the main thread so it will be logged and captured there
     * @param  {Object | String} error
     */
    Logger.surfaceError = function (error) {
      // if we are in a worker enviroment
      if (!global.window && global.postMessage) {
        global.postMessage({
          command: 'error',
          error: error
        })
      }
    }

    return Logger
  })()

  // make sure we have the vars available
  // it should only activate on the recording page (check for app.project)
  if (global.servars && global.servars.logging) {
    // This is called for window context only.
    // On workers context, there is no servars, so there are other explicit calls to Logger.initialize()
    Logger.initialize(global.servars.logging)
  }

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = Logger
  else global.Logger = Logger
})()
