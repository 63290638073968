/* globals debug streamify CloudStore ResumableUploadGcs ResumableUploadB2 */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:uploadStreamNode')

  var UploadStreamNode = (function () {
    var UploadStreamNode = function (config) {
      CloudStore.call(this, config)
      dbg.enabled = config.debug
      dbg('Initialized with config: ', config)

      if (config.messagePort && !(config.messagePort instanceof MessagePort)) {
        throw new Error('config.messagePort must an instance of MessagePort')
      }

      // if we've received the last chunk
      this.receivedLastChunk = false
      // upload queue
      this.uploadBuffer = {
        chunk: new Blob(),
        isLast: false
      }
      this.chunkIndex = 0
      this.bytesUploaded = 0

      this.shouldReportProgress = false
      this.usePacing = !!config.usePacing

      this.messagePort = config.messagePort
      if (this.messagePort) {
        this.initPortListeners()
        // listen to the objects own upload events
        this.initUploadEventsListeners()
      }

      this.on('incomingChunk', this.processChunk)
      this.on('connectionStatusChanged', this.connectionStatusChanged)

      // if (this.uploadUrl) this.initStreamingUpload()
    }

    // must be first after constructor
    UploadStreamNode.prototype = Object.create(CloudStore.prototype)

    UploadStreamNode.prototype.setForceFinalize = function () {
      this._forceFinalize = true
      if (!this.uploadInProgress) {
        this.setForceFinalizeTimer()
      }
    }

    UploadStreamNode.prototype.setForceFinalizeTimer = function () {
      this.cancelForceFinalizeTimer()
      this._forceFinalizeTimer = setTimeout(() => {
        this.forceFinalize()
      }, 1000)
    }

    UploadStreamNode.prototype.cancelForceFinalizeTimer = function () {
      if (this._forceFinalizeTimer) {
        clearTimeout(this._forceFinalizeTimer)
        this._forceFinalizeTimer = undefined
      }
    }

    UploadStreamNode.prototype.forceFinalize = function () {
      if (this._forceFinalize) {
        this.uploadChunk(new Blob([0]), true)
      }
    }

    UploadStreamNode.prototype.initPortListeners = function (e) {
      var port = this.messagePort
      port.onmessage = (e) => {
        switch (e.data.command) {
          case 'createUploadUrl':
            this.createUploadUrl(e.data.projectId, e.data.recordingId, e.data.uploadId, e.data.hostId, e.data.path).then(function (url) {
              port.postMessage({id: e.data.id, result: url})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'initStreamingUpload':
            this.initStreamingUpload(e.data.url).then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'upload':
            this.upload(e.data.url, e.data.blob).then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'createDownloadUrl':
            this.createDownloadUrl(e.data.projectId, e.data.path).then(function (url) {
              port.postMessage({id: e.data.id, result: url})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'getResumableUploadProgress':
            this.getResumableUploadProgress().then(function (progress) {
              port.postMessage({id: e.data.id, result: progress})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'completeMultipartUpload':
            this.completeMultipartUpload().then(function (result) {
              port.postMessage({ id: e.data.id, result })
            }).catch(function (err) {
              port.postMessage({ id: e.data.id, err: err.toString() })
            })
            break
        }
      }

      port.postMessage({command: 'ready'})
    }

    UploadStreamNode.prototype.initUploadEventsListeners = function () {
      var port = this.messagePort

      // listen to own events so we can proxy them back through the message channel
      this.on('chunkUploaded', function (chunkMeta) {
        port.postMessage({command: 'chunkUploaded', chunkMeta: chunkMeta})
      })
      this.on('uploadMultipartCompleted', function () {
        port.postMessage({ command: 'uploadMultipartCompleted' })
      })
      this.on('change:bytesUploaded', function (bytesUploaded) {
        port.postMessage({command: 'change:bytesUploaded', bytesUploaded: bytesUploaded})
      })
    }

    /**
     * Main method of the module
     * Handles incoming chunk from previous node
     * @param  {Blob}  chunk  Audio/video data
     * @param  {Boolean} isLast If it's the last chunk
     * @return {void}
     */
    UploadStreamNode.prototype.processChunk = function (chunk, isLast) {
      chunk = chunk || new Blob()
      this.cancelForceFinalizeTimer()

      // when the last chunk has reached the queue
      if (isLast) {
        this._forceFinalize = false
        // start reporting upload progress
        this.shouldReportProgress = true
        // we can also stop using pacing
        this.uploader.shouldUsePacing = false
      }

      if (this.receivedLastChunk) {
        console.warn('Received last chunk after `isLast`')
      }

      this.receivedLastChunk = isLast

      this.uploadBuffer = {
        chunk: new Blob([this.uploadBuffer.chunk, chunk]),
        isLast: isLast
      }

      this.processQueue()

      this.trigger('outgoingChunk', chunk, isLast)
    }

    UploadStreamNode.prototype.processQueue = function () {
      // if we are uploading now, then the queue will take care of the new chunk
      if (this.uploadInProgress || !this.uploadBuffer.chunk.size) return

      // if it's backblaze, force 5 MB chunks, otherwise, keep the current behavior
      if (this.provider === 'b2') {
        this.processQueueB2()
      } else {
        this.processQueueGcs()
      }
    }

    UploadStreamNode.prototype.processQueueGcs = function () {
      // get the next blob to process
      let {chunk, isLast} = this.uploadBuffer

      // if it's the last chunk, we don't care, always upload
      if (isLast) {
        this.uploadChunk(chunk, isLast)
        return
      }

      // if it's larger than chunkSize
      if (chunk.size >= this.chunkSize) {
        // if it's a multiple of chunkSize, just upload
        if (chunk.size % this.chunkSize === 0) {
          this.uploadChunk(chunk, isLast)
        } else {
          // if not, take a bite
          var multiple = chunk.size / this.chunkSize
          var limit = this.chunkSize * Math.floor(multiple)

          var toUpload = chunk.slice(0, limit)

          this.uploadChunk(toUpload, isLast)
        }
      }

      // else, if it's smaller, just exit and wait for new chunks
    }


    UploadStreamNode.prototype.processQueueB2 = function () {
      // get the next blob to process
      let {chunk, isLast} = this.uploadBuffer
      
      // if it's the last chunk and fits the chunk size limit, we don't care, always upload
      if (isLast && chunk.size <= this.chunkSize) {
        this.uploadChunk(chunk, isLast)
        return
      }

      // if it's larger than chunkSize
      if (chunk.size >= this.chunkSize) {
        // if it's equal to the chunkSize, just upload
        if (chunk.size === this.chunkSize) {
          this.uploadChunk(chunk, isLast)
        } else {
          // if not, take a bite of the exact chunk size, not a multiple as we do on GCS
          var toUpload = chunk.slice(0, this.chunkSize)

          this.uploadChunk(toUpload, false) // We override the isLast here, because we are sending just a bite, so it's not the last yet
        }
      }

      // else, if it's smaller, just exit and wait for new chunks
    }

    UploadStreamNode.prototype.connectionStatusChanged = function (isConnected) {
      if (this.uploader)
        this.uploader.connectionStatusChanged(isConnected)
    }

    UploadStreamNode.prototype.uploadChunk = function (chunk, isLast) {
      this.uploadInProgress = true

      let chunkBytesUploadedCallbackCalled = false
      const chunkBytesUploadedCallback = () => {
        if (chunkBytesUploadedCallbackCalled) return
        chunkBytesUploadedCallbackCalled = true

        // remove uploaded blob from queue
        this.uploadBuffer.chunk = this.uploadBuffer.chunk.slice(chunk.size)
        // unset the uploadInProgress to allow the next chunk to come in
        this.uploadInProgress = false

        // and force process again to see if we have any more blobs
        this.processQueue()
      }
      
      if (this.shouldReportProgress) {
        this.uploader.enableProgressTracker()
      }

      const partNumber = (this.lastPartNumber || 0) + 1
      this.lastPartNumber = partNumber

      let uploadPromise
      
      if (this.provider === 'b2') {
        uploadPromise = this.uploader.uploadChunk(chunk, partNumber, isLast)
      } else {
        uploadPromise = this.uploader.uploadChunk(chunk, partNumber, isLast)
      }

      uploadPromise.then(({ etag }) => {
        chunkBytesUploadedCallback()
        this.processSucceded(chunk.size, partNumber, etag, isLast)
      })

      uploadPromise.catch((err) => {
        console.error(err)
        this.uploader.disableProgressTracker()
        // signal the track that the upload got into a unrecoverable state
        this.messagePort && this.messagePort.postMessage({command: 'abort'})
        this.uploadBuffer = null
      })
    }

    UploadStreamNode.prototype.processSucceded = function (chunkSize, partNumber, etag, isLast) {
      this.chunkIndex++
      this.bytesUploaded += chunkSize
      this.parts.push({ etag, partNumber })

      this.trigger('change:bytesUploaded', this.bytesUploaded)
      this.trigger('chunkUploaded', {
        etag,
        partNumber,
        chunkIndex: this.chunkIndex,
        uploadedBytes: this.bytesUploaded,
        isLastChunk: isLast
      })

      if (isLast) {
        this.uploader.disableProgressTracker()
      } else if (!this.uploadBuffer.chunk.size && this._forceFinalize) {
        this.forceFinalize()
      }
    }

    UploadStreamNode.prototype.recreateUploadUrl = function () {
      if (this.uploader) {
        this.uploader.disableProgressTracker()
      }
      this.uploader = null
      if (this.messagePort) {
        this.messagePort.postMessage({command: 'recreateUploadUrl'})
      }
    }

    UploadStreamNode.prototype.initStreamingUpload = function () {
      return new Promise((resolve, reject) => {
        // if the upload has already been initialized, don't allow it again
        if (this.uploader) {
          console.warn('Called initStreamingUpload a second time')
          resolve()
          return
        }

        const ResumableUpload = this.provider === 'b2' ? ResumableUploadB2 : ResumableUploadGcs
        this.uploader = new ResumableUpload({
          url: this.uploadUrl,
          shouldUsePacing: this.usePacing,
          contentType: this.getMimeType(),
          progressCb: (uploaded) => this.trigger('change:bytesUploaded', uploaded)
        })

        resolve()
      })
    }

    UploadStreamNode.prototype.cleaupProgressTracking = function () {
      if (this.uploader)
        this.uploader.disableProgressTracker()
    }

    streamify.mixin(UploadStreamNode.prototype)

    return UploadStreamNode
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = UploadStreamNode
  else global.UploadStreamNode = UploadStreamNode
})()
